<template>
  <div class="rcc-radio-group">
    <rcc-radio-button
      v-for="(option, index) in options"
      :key="index"
      :label="option.label"
      :value="option.value"
      :name="name"
      :isChecked="option.value === value"
      :isDisabled="option.isDisabled"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import RccRadioButton from './rcc-radio-button.vue'

export default {
  components: { RccRadioButton },

  name: 'rcc-radio-group',

  props: {
    options: {
      type: Array,
      default: () => []
    },

    value: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: 'rcc-radio-group-' + Math.floor(Math.random() * (99999999 - 10000 + 1)) + 10000
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-radio-group {
	display: flex;
	align-items: center;
	gap: 20px;
}
</style>
