<template>
  <label class="rcc-radio-button">
    <input
      type="radio"
      :name="name"
      class="rcc-radio-button__check-input"
      @input="$emit('input', value)"
      :checked="isChecked"
      :disabled="isDisabled"
    />
    <span class="rcc-radio-button__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'rcc-radio-button',

  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    isChecked: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
$radio-button: '.rcc-radio-button';

#{$radio-button} {
  display: flex;
	cursor: pointer;
  color: $base-text-color;

  &__label {
		display: flex;
		align-items: baseline;
    padding-left: 25px;
    transition: color .4s ease;

		&:before {
      content: '';
      position: absolute;
			display: flex;
      border-radius: 50%;
      left: 0;
			width: 20px;
			height: 20px;
			transition: border-color .4s ease, box-shadow 0.25s ease;
      border: 1px solid $radio-border-color;
      z-index: 2;
		}

    &:after {
      content: '';
      position: absolute;
			display: flex;
      border-radius: 50%;
      left: 0;
			width: 20px;
			height: 20px;
      transition: border-color .4s ease;
      border: 2px solid $radio-border-color;
      z-index: 1;
		}

    &:hover {
      color: $primary;

      &::before {
        border-color: $primary;
      }

      &:after {
        border-color: $primary;
			}
		}
	}

  &__check-input {
    display: none;

		&:checked + #{$radio-button}__label {
      color: $primary;

			&:before {
        border: 1px solid $primary;
				box-shadow: inset 0 0 0 0.4375em $primary;
			}

      &:after {
        border-color: $primary;
			}
		}

    &:disabled + #{$radio-button}__label {
      color: $checkbox-disabled;
      cursor: default;

      &:before {
        border: 1px solid $checkbox-disabled;
        border-color: $checkbox-disabled;
				box-shadow: none;
			}

      &:after {
        border-color: $checkbox-disabled;
			}
    }
  }
}
</style>
